import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"
import { BlogList, Container, Layout, Section, BackButton } from "../components"
import styled from "styled-components"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout>
      <Section>
        <Container>
          <div
            css={`
              padding-top: 1rem;
            `}
          >
            <BackButton to="/tags/" text="All Tags" />
            <Header>{tagHeader}</Header>
            <BlogList posts={edges} />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

const Header = styled.h2`
  color: var(--primary);
`

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            path
            date
            author
            length
          }
        }
      }
    }
  }
`
